import React from "react";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <section className="hero">
      <div className="container mx-auto flex items-center justify-center w-auto h-auto py-20 px-8">
        <div className="md:text-5xl sm:text-4xl text-3xl md:space-x-20 space-x-10">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            className="mb-10 tracking-tighter max-w-4xl leading-[1.2]"
          >
            <h1>
              Hi, I'm{" "}
              <span className="text-neutral-800 font-semibold">
                Sarah Grace
              </span>
              , a digital designer and developer based in{" "}
              <span className="text-neutral-800 font-semibold">
                Derbyshire, United Kingdom
              </span>
              .
            </h1>
          </motion.h1>
        </div>
      </div>
    </section>
  );
};

export default Home;
