import React from "react";
import { motion } from "framer-motion";

const Header = () => {
  return (
    <section className="Header">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
        className="container mx-auto flex justify-between pt-5 pb-20 px-8"
      >
        <div>
          <svg
            className="justify-start"
            width="60"
            height="60"
            viewBox="0 0 280 280"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="88.4" cy="88.4" r="48.4" fill="black" />
            <path
              d="M143.2 88.4005C143.2 61.6699 164.87 40.0005 191.6 40.0005V40.0005C218.331 40.0005 240 61.6699 240 88.4005V88.4005C240 115.131 218.331 136.8 191.6 136.8H143.2V88.4005Z"
              fill="black"
            />
            <path
              d="M40 191.6C40 164.87 61.6694 143.2 88.4 143.2H136.8V191.6C136.8 218.331 115.131 240 88.4 240V240C61.6694 240 40 218.331 40 191.6V191.6Z"
              fill="black"
            />
            <circle cx="191.6" cy="191.6" r="48.4" fill="black" />
            <path
              d="M200.409 187C200.097 186.044 199.685 185.187 199.173 184.429C198.671 183.662 198.07 183.009 197.369 182.469C196.678 181.929 195.892 181.517 195.011 181.233C194.131 180.949 193.165 180.807 192.114 180.807C190.39 180.807 188.823 181.252 187.412 182.142C186.001 183.032 184.879 184.344 184.045 186.077C183.212 187.81 182.795 189.936 182.795 192.455C182.795 194.973 183.217 197.099 184.06 198.832C184.902 200.565 186.044 201.877 187.483 202.767C188.922 203.657 190.542 204.102 192.341 204.102C194.008 204.102 195.475 203.747 196.744 203.037C198.023 202.317 199.017 201.304 199.727 199.997C200.447 198.681 200.807 197.133 200.807 195.352L201.886 195.58H193.136V192.455H204.216V195.58C204.216 197.975 203.705 200.059 202.682 201.83C201.669 203.6 200.267 204.973 198.477 205.949C196.697 206.915 194.652 207.398 192.341 207.398C189.765 207.398 187.502 206.792 185.551 205.58C183.61 204.367 182.095 202.644 181.006 200.409C179.926 198.174 179.386 195.523 179.386 192.455C179.386 190.153 179.694 188.084 180.31 186.247C180.935 184.401 181.815 182.829 182.952 181.531C184.088 180.234 185.433 179.24 186.986 178.548C188.539 177.857 190.248 177.511 192.114 177.511C193.648 177.511 195.078 177.743 196.403 178.207C197.739 178.662 198.927 179.311 199.969 180.153C201.02 180.987 201.896 181.986 202.597 183.151C203.297 184.306 203.78 185.589 204.045 187H200.409Z"
              fill="white"
            />
            <path
              d="M94.4318 81.1818C94.2614 79.7424 93.5701 78.625 92.358 77.8295C91.1458 77.0341 89.6591 76.6364 87.8977 76.6364C86.6098 76.6364 85.483 76.8447 84.517 77.2614C83.5606 77.678 82.8125 78.2509 82.2727 78.9801C81.7424 79.7093 81.4773 80.5379 81.4773 81.4659C81.4773 82.2424 81.6619 82.91 82.0312 83.4688C82.41 84.018 82.893 84.4773 83.4801 84.8466C84.0672 85.2064 84.6828 85.5047 85.3267 85.7415C85.9706 85.9687 86.5625 86.1534 87.1023 86.2955L90.0568 87.0909C90.8144 87.2898 91.6572 87.5644 92.5852 87.9148C93.5227 88.2652 94.4176 88.7434 95.2699 89.3494C96.1316 89.946 96.8419 90.7131 97.4006 91.6506C97.9593 92.5881 98.2386 93.7386 98.2386 95.1023C98.2386 96.6742 97.8267 98.0947 97.0028 99.3636C96.1884 100.633 94.9953 101.641 93.4233 102.389C91.8608 103.137 89.9621 103.511 87.7273 103.511C85.6439 103.511 83.84 103.175 82.3153 102.503C80.8002 101.83 79.607 100.893 78.7358 99.6903C77.8741 98.4877 77.3864 97.0909 77.2727 95.5H80.9091C81.0038 96.5985 81.3731 97.5076 82.017 98.2273C82.6705 98.9375 83.4943 99.4678 84.4886 99.8182C85.4924 100.159 86.572 100.33 87.7273 100.33C89.072 100.33 90.2794 100.112 91.3494 99.6761C92.4195 99.2311 93.267 98.6155 93.892 97.8295C94.517 97.0341 94.8295 96.1061 94.8295 95.0455C94.8295 94.0795 94.5597 93.2936 94.0199 92.6875C93.4801 92.0814 92.7699 91.589 91.8892 91.2102C91.0085 90.8314 90.0568 90.5 89.0341 90.2159L85.4545 89.1932C83.1818 88.5398 81.3826 87.607 80.0568 86.3949C78.7311 85.1828 78.0682 83.5966 78.0682 81.6364C78.0682 80.0076 78.5085 78.5871 79.3892 77.375C80.2794 76.1534 81.4725 75.2064 82.9688 74.5341C84.4744 73.8523 86.1553 73.5114 88.0114 73.5114C89.8864 73.5114 91.553 73.8475 93.0114 74.5199C94.4697 75.1828 95.625 76.0919 96.4773 77.2472C97.339 78.4025 97.7936 79.714 97.8409 81.1818H94.4318Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex items-center gap-5 md:text-xl hover:text-gray-900">
          <a href="mailto:Sarahgwakefield1@gmail.com">Contact</a>
          <a href="https://www.linkedin.com/in/sarah-grace-927932262/">
            LinkedIn
          </a>
        </div>
      </motion.div>
    </section>
  );
};

export default Header;
