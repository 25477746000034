import React from "react";
import Sarah from "../assets/SarahSketch.jpg";
import { motion } from "framer-motion";

const Slider = () => {
  return (
    <section className="Slider">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.8 }}
        className="container mx-auto flex flex-col-1 justify-center items-center px-8 py-8 lg:px-20"
      >
        <div className="">
          <img
            className="object-contain rounded-2xl"
            src={Sarah}
            alt="A sketch of Sarah Grace"
          />
        </div>
      </motion.div>
    </section>
  );
};

export default Slider;
