import React from "react";

const Footer = () => {
  return (
    <section className="Footer">
      <div className="container mx-auto flex justify-between text-xs md:text-lg py-10 px-8">
        <p>@SarahGrace</p>
        <p>Top</p>
      </div>
    </section>
  );
};

export default Footer;
