import Header from "./Components/Header";
import Home from "./Components/Home";
import Slider from "./Components/Slider";
import About from "./Components/About";
import Services from "./Components/Services";
import Links from "./Components/Links";
import Footer from "./Components/Footer";

window.addEventListener("blur", ()=> {
  document.title = "see you soon <3 - Beautifully hand crafted websites";
})

window.addEventListener("focus", () => {
  document.title = "Beautifully hand crafted websites"
})

function App() {
  return (
    <div className="font-inter text-neutral-500 tracking-tighter">
      <Header/>
      <Home/>
      <Slider/>
      <About/>
      <Services/>
      <Links/>
      <Footer/>
    </div>
  );
}

export default App;
