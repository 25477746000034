import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <section className="About">
      <div className="container mx-auto flex items-center justify-center w-auto h-auto py-20 px-8 md:pr-80">
        <div className="md:text-2xl sm:text-xl text-xl space-y-14 tracking-tight">
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
            viewport={{ once: true, amount: 0.5 }}
            className="max-w-2xl  leading-normal"
          >
            As a freelance website developer, I specialize in creating custom,
            professional websites for small businesses and startups. With a few
            years of experience under my belt, I know how to craft a site that
            not only looks great, but is also optimized for search engines and
            user experience. <br />
          </motion.h3>
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.6 }}
            viewport={{ once: true, amount: 0.5 }}
            className="max-w-2xl text-neutral-800 md:text-3xl leading-normal font-semibold"
          >
            I work with small buisnesses and startups — Helping to develop your
            ideas and establish an online presence
          </motion.h2>
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.7 }}
            viewport={{ once: true, amount: 0.5 }}
            className="max-w-2xl  leading-normal"
          >
            My passion is getting companies to succeed online, and I'm always
            learning new techniques and technologies to keep up with the
            fast-paced world of web development.
          </motion.h3>
        </div>
      </div>
    </section>
  );
};

export default About;
